.assessment-card {
    width: 100%; 
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .assessment-card h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #062784;
  }

  .assessments-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .assessment-item-card {
    padding: 15px 20px;
    border: 1px solid #062784 ;
    border-radius: 6px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .assessment-item-card .assessment-title {
    margin: 0;
    font-size: 1.1rem;
    color: #555;
  }

  .take-assessment-button {
    background: #EA9D3D;
    color: #fff;
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .take-assessment-button:hover {
    background: #218838;
    transform: translateY(-2px);
  }
  