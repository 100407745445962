body {
  margin: 0;
  font-family: "Nunito Sans", system-ui;
}
/* MEDIA QUERY */

@media (max-width: 575px) {
 body::-webkit-scrollbar{
   display: none;
 }
}

/**
 * responsive for large than 768px screen
 */

@media (max-width: 768px) {

}

@media (max-width: 992px) {

}

/**
 * responsive for large than 1200px screen
 */

@media (max-width: 1200px) {
}

