
.attendance-circle-graph{
    text-align: center;
    margin-top: 1em; 
  }
  .attendance-details{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 1.3em;
    border-radius: 20px;
    color: #0961A2;
    font-weight: bold;
    border: 4px #0961A2 solid;
  }