/* Hero Section */
.hero-Section {
    position: relative;
    top: 3em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #DAE8F1;
    height: 100%;
    flex-wrap: wrap;
    padding: 1rem;
}

.first-half, .second-half {
    transition: transform 1s ease-out, opacity 1s ease-out;
}

.first-half {
    opacity: 0;
    transform: translateY(100%);
}

.second-half {
    opacity: 0;
    transform: translateX(-100%);
}

.first-half.slide-up {
    opacity: 1;
    transform: translateY(0);
}

.second-half.slide-right {
    opacity: 1;
    transform: translateX(0);
}

.appear {
    opacity: 1;
}

/* Keyframes for rotating blobs animation */
@keyframes keyframes-rotate-blubs {
    0% {
        transform: translate(10px) rotate(360deg);
    }
    50% {
        transform: translate(-5px, 10px) rotate(180deg);
    }
    100% {
        transform: translate(10px) rotate(0deg);
    }
}

/* First Section */
.first-section {
    width: 30%;
    align-items: center;
    position: relative;
    user-select: none;
    padding: 1rem;
}

/* Banner Text */
.banner-text {
    /* margin-top: 2rem; */
    display: flex;
    flex-direction: column;
}

.banner-text .text2 {
    margin-top: -5rem;
}

.banner-text .text3 {
    margin-top: -5rem;
}

/* Heading Style */
h1 {
    padding: -5rem;
    font-size: 6rem;
    color: rgba(6, 39, 132, 0.647);
}

/* Second Section */
/* Card Styles */
.card {
    width: 800px;
    height: 550px;
    border-radius: 4px;
    margin-top: 3rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    display: flex;
    gap: 30px;
    padding: .4em;
    user-select: none;
}

.card .card1 {
    height: 100%;
    width: 100%;
    flex: 3;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
    transition: all .8s;
    background: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.card1 img {
    height: 120%;
    display: block;
    position: relative;
    transition: transform 0.5s;
}

.card .card1:hover {
    flex: 8;
}

.card .card1 span {
    min-width: 14em;
    padding: .5em;
    text-align: center;
    transform: rotate(-90deg);
    transition: all .5s;
    text-transform: uppercase;
    color: #ff568e;
    letter-spacing: .1em;
}

.card img:hover span {
    transform: rotate(0);
}

.card .card1 .overlay-text1 {
    position: absolute;
    font-size: 30px;
    
    margin-right: 4.2rem;
    margin-top: 11rem;
    transform: rotate(270deg);
    transform-origin: right unset;
    color: white;
    overflow: visible;
    transition: opacity 0.2s ease-in-out;
}

.card .card1 .overlay-text1::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 42%;
    transform: translate(-75%, -50%);
    width: 70%;
    height: 100%;
    background-color: rgba(88, 74, 163, 0.492);
    z-index: -1;
    border-radius: inherit;
}

.card .card1:hover .overlay-text1 {
    opacity: 0;
}

.card .card1 .overlay-text2 {
    position: absolute;
    font-size: 30px;
    margin-left: -8.5rem;
    padding-left: 3rem;
    margin-right: 10rem;
    margin-top: 24rem;
    color: white;
    overflow: visible;
    transition: opacity 1s ease-in;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    z-index: 1;
}

.card .card1 .overlay-text2::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 42%;
    transform: translate(-45%, -50%);
    width: 70%;
    height: 60%;
    background-color: rgba(88, 74, 163, 0.492);
    z-index: -1;
    border-radius: inherit;
}

.card .card1:hover .overlay-text2 {
    opacity: 1;
}

.bannerhr {
    border: none;
    border-top: 3px solid #f5f3f3;
    margin-top: -2.5rem;
    margin-left: 5px;
}

.h4-text {
    margin-top: -1rem;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(6, 39, 132, 0.647);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.card1:hover .overlay {
    opacity: 1;
}

/* Button Style */
.btn {
    padding: 1rem 2rem;
    font-weight: 700;
    background: rgb(255, 255, 255);
    color: #0b68af;
    border-radius: .5rem;
    border-bottom: 3px solid #0b68af;
    border-right: 3px solid #0b68af;
    border-top: 3px solid white;
    border-left: 3px solid white;
    transition-duration: 1s;
    transition-property: border-top,
       border-left,
       border-bottom,
       border-right,
       box-shadow;
}

.btn:hover {
    border-top: 2px solid #0b68af;
    border-left: 2px solid #0b68af;
    border-bottom: 2px solid #52d4eb;
    border-right: 2px solid #52d4eb;
    box-shadow: rgba(78, 78, 243, 0.4) 5px 5px, rgba(43, 28, 138, 0.3) 10px 10px, rgba(12, 9, 104, 0.2) 15px 15px;
    cursor: pointer;
}

.buttons {
    display: flex;
    gap: 2rem;
    margin-top: -3em;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .hero-Section {
        
        flex-direction: column;
        align-items: center;
    }

    .first-section {
        width: 90%;
    }

    .card {
        width: 100%;
        max-width: 800px;
        height: auto;
    }

    .card .card1 {
        flex: 3;
        height: 350px;
    }
    .card .card1 .overlay-text1{
        font-size: 25px;
    }
    .card .card1 .overlay-text2{
        font-size: 25px;
    }

    .card1 img {
        /* position: relative; */
        height: 100%;
        
    }
}

@media (max-width: 768px) {
    .banner-text .text2, .banner-text .text3 {
        margin-top: -3rem;
    }

    h1 {
        font-size: 4rem;
    }

    .card {
        flex-direction: column;
    }

    .card .card1 {
        flex: 1 1 100%;
        height: 300px;
    }

    .card1 img {
        height: auto;
        width: 100%;
    }

    .buttons {
        margin-top: 1rem;
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .banner-text .text2, .banner-text .text3 {
        margin-top: -2rem;
    }

    h1 {
        font-size: 3rem;
    }

    .btn {
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }

    .buttons {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .card {
        margin-top: 1rem;
    }

    .card .card1 {
        height: 200px;
    }
}

.hero-Section {
    /* Existing styles for desktop view */
}

/* Desktop Animations */
.slide-up {
    animation: slideUp 1s forwards;
}

.slide-right {
    animation: slideRight 1s forwards;
}

/* Mobile View */
/* Mobile View */
@media (max-width: 768px) {
    .mobile-hero-section {
        padding: 1rem;
        text-align: center;
    }

    .mobile-first-half {
        margin-bottom: 1.8rem;
    }

    .mobile-banner-text {
        font-size: 1.8rem;
        color: #062784;
        margin-bottom: 1rem;
    }

    .mobile-buttons {
        display: flex;
        justify-content: center;
        gap: 1rem;
    }

    .mobile-btn {
        background-color: #E89F3E;
        color: white;
        padding: 0.5rem 1.5rem;
        font-size: 0.9rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .mobile-btn:hover {
        background-color: #d57d2c;
    }

    .swipeable-container {
        overflow: hidden;
        width: 100%;
    }

    .mobile-cards {
        display: flex;
        transition: transform 0.5s ease-in-out;
        overflow-x: scroll;
        gap: 0.5rem;
    }

    .mobile-card {
        min-width: 80%;
        background-color: #f9f9f9;
        border-radius: 20px;
        
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        
    }

    .mobile-card-img {
        width: 100%;
        height: auto;
    }

    .mobile-card-content {
        margin-top: -1.5rem;
        padding: 0.5rem 1rem;
        text-align: left;
        
    }

    .mobile-card-title {
        font-size: 1.2rem;
        color: #062784;
    }

    .mobile-card-topics {
        
        font-weight: bold;
        margin-top: -1.2rem;
        font-weight: bold;
        color: #E89F3E;
        font-size: 0.9rem;
        
    }
}


@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRight {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInCards {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

