.assignmentcard-section{
    width: 95%;
    border: 1px solid black;
    border-radius: 20px;
    background-color: #062784;
}
.assignmentcard-container{
    display: flex;
    justify-content: space-between;
    padding: 1em;
}
.assignment-view-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.assignment-view-btn > button{
    background-color: transparent;
    padding: 0.5em;
    border: 2px solid white;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
    color: white;
    width: 10em;
}
.assignment-view-btn > button:hover{
    background-color: rgb(0 0 0 / 30%);
}
.assignment-name{
    font-size: 1.5em;
    font-weight: 800;
    color: white;
}
.assignment-date{
    font-size: 1em;
    font-weight: 500;
    color: white;
}

@media screen and (max-width: 768px) {
    .assignmentcard-container{
        flex-direction: column;
        align-items: center;
    }
    .assignment-date{
        text-align: center;
        margin-top: -1em;
    }
}