/* Background overlay */
.otp-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content styling */
  .otp-modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    width: 400px;
    max-width: 95%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    animation: slideIn 0.3s ease-in-out;
  }
  
  /* Smooth slide-in animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Modal title styling */
  .otp-modal-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Subtitle under the title */
  .otp-modal-subtitle {
    font-size: 16px;
    color: #777;
    margin-bottom: 20px;
  }
  
  /* OTP input styling */
  .otp-input {
    font-size: 18px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    text-align: center;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .otp-input:focus {
    border-color: #4caf50;
  }
  
  /* Submit button styling */
  .otp-submit-btn {
    background-color: #4caf50;
    color: #fff;
    font-size: 16px;
    padding: 12px 20px;
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .otp-submit-btn:hover {
    background-color: #45a049;
  }
  
  /* Responsive for smaller screens */
  @media (max-width: 600px) {
    .otp-modal-content {
      width: 90%;
    }
  }
  