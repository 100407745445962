/* Container */
.profile-container {
    width: 100%;
    padding: 20px;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes scale-animation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.02); /* Subtle scaling */
    }
  }
  
/* Banner */
.profile-banner {
    width: 100%;
    height: 200px;
    position: relative;
    background-color: #062784;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.banner-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,  #062784, #81B9E8);
    animation: scale-animation 6s infinite alternate;
    z-index: 0;
}

@keyframes scale-animation {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}

.profile-image {
    width: 120px;
    height: 120px;
    border: 4px solid #fff;
    object-fit: cover; 
    margin-left: 20px;
  }
  

.user-info {
    z-index: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 2rem;
}

.user-name {
    font-weight: bolder;
    color: #fff; 
    margin: 0;
    font-size: 2rem;
}

.user-role {
    margin: 0;
    color: #EB9F39; 
    font-size: 1.2rem;
}

.edit-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    cursor: pointer;
    z-index: 1;
}

.social-media-icons {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
    z-index: 1;
}

/* Details Section */
.details-section {
    display: flex;
    width: 100%;
    margin-top: 20px;
    gap: 30px;
}

.user-details-card {
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 550px;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .user-details-heading {
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    color: #062784;
  }
  
  .user-details-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .user-detail-item {
    display: flex;
    justify-content: space-between;
    /* padding: 10px 0; */
    border-bottom: 1px solid #f0f0f0;
  }
  
  .user-detail-item strong {
    color: #062784;
  }
  
  .user-detail-item span {
    color: #555;
  }
  

.user-details-card,
.reset-password-card {
    width: 50%;
    background: #f5f5f5; 
    /* padding: 20px; */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* .user-details-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  color: #333;
  font-family: 'Arial', sans-serif;
} */

.user-details-heading {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  color: #062784;
}

.user-details-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user-detail-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
}

.user-detail-item strong {
  color: #062784;
}

.user-detail-item span {
  color: #555;
}


/* Reset Password Card */
.reset-password-card {
    width: 50%;
    background: hsl(0, 0%, 96%);
    padding: 10px;
    padding-left: 3rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reset-heading {
    margin: 0.5rem;
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: #062784; 
}

.reset-input {
    margin-bottom: 15px;
}

.reset-input label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.reset-input input:focus {
    border-color: #EB9F39; 
    box-shadow: 0 0 8px rgba(235, 159, 57, 0.5);
    outline: none;
}

button[type="submit"] {
    width: 50%;
    padding: 15px;
    background-color: #EB9F39; 
    color: white;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #81B9E8; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

button[type="submit"]:active {
    transform: scale(0.98);
}

/* Password Input Wrapper */
.password-input-wrapper {
    position: relative;
    width: 80%;
}

.password-input-wrapper input {
    width: 100%;
    padding-right: 40px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.password-input-wrapper input:focus {
    border-color: #EB9F39; 
    box-shadow: 0 0 8px rgba(235, 159, 57, 0.5);
    outline: none;
}

.password-toggle-icon {
    position: absolute;
    right: 0px;
    top: 60%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #aaa;
    transition: color 0.3s ease;
}

.password-toggle-icon:hover {
    color: #333;
}

/* Stats Section */
.stats-section {
    /* display: flex; */
    width: 100%;
    margin-top: 20px;
    gap: 2rem;
    
}
.first-row {
    display: flex;
    gap: 2rem;
    margin-bottom: 2em;
}
.second-row {
    display: flex;
    gap: 2rem;
}

.small-card {
    width: 100%;
    height: 150px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.small-card .card-heading{
  margin-top: 2rem;
  margin-bottom: 0.7rem;
  color: #062784;
}

.small-card .card-paragraph{
  margin: 0;
  font-size: 1.4rem;
  color: #EB9F39;
  font-weight: bolder;

}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: modal-slide-in 0.3s ease;
  }
  
  @keyframes modal-slide-in {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .modal-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-error {
    border-color: red;
  }
  
  .error-text {
    color: red;
    font-size: 0.8rem;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .save-btn, .cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-btn {
    background-color: #062784;
    color: white;
  }
  
  .cancel-btn {
    background-color: #ccc;
    color: black;
  }
  
  .sociallink1,
  .sociallink2,
  .form-group1{
    display: flex;
    gap: 2rem;
  }

  .stats-section{
    width: 50%;
   }

/* MEDIA QUERY */

@media (max-width: 575px) {
 .details-section{
  flex-direction: column;
 } 
 .profile-container{
  padding: 1.8em;
 }
 .stats-section{
  width: 100%;
 }
 .reset-password-card{
  width: 90vw;
  padding: 1em;
 }
 .reset-form{
  text-align: center;
 }
 .password-input-wrapper{
  width: 93%;
 }
}

/**
 * responsive for large than 768px screen
 */

@media (max-width: 768px) {
  .details-section{
    flex-direction: column;
   } 
   .profile-container{
    padding: 1.8em;
   }
   .stats-section{
    width: 100%;
   }
   .reset-password-card{
    width: 90vw;
    padding: 1em;
   }
   .reset-form{
    text-align: center;
   }
   .password-input-wrapper{
    width: 93%;
   }
}

@media (max-width: 992px) {

}

/**
 * responsive for large than 1200px screen
 */

@media (max-width: 1200px) {
}

