.assignment-inner-details-main {
    background-color: #f8f9fa; /* Light background for a modern look */
    /* border-radius: 10px; */
    padding: 20px 20px 0 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    /* max-width: 800px; */
    width: 99%;
    /* margin: 20px auto; */
    font-family: 'Roboto', sans-serif;
    height: 100%;
  }

  
  /* Heading and button container */
  .assignment-heading-upload-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Assignment title */
  .assignment-inner-heading {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Upload button */
  .assignment-upload-btn {
    background-color: #e89f3c;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .assignment-upload-btn:hover {
    background-color: #d07e26;
  }
  
  /* Explanation section styling */
  .assignment-inner-details-explanation {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    line-height: 1.6;
    color: #555;
    font-size: 1rem;
    margin-bottom: 20px;
    border: 1px solid #ddd;
  }
  
  /* File upload form */
  .assignment-upload-form {
    background-color: #ffffff;
    border: 2px dashed #d07e26;
    padding: 30px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    color: #d07e26;
    transition: background-color 0.3s ease;
  }
  
  .assignment-upload-form:hover {
    background-color: #faf3eb;
  }
  
  .assignment-upload-form input[type="file"] {
    display: none;
  }
  
  .upload-instructions {
    margin-top: 10px;
    color: #666;
    font-size: 0.9rem;
  }
  
  /* Mobile view adjustments */
  @media screen and (max-width: 768px) {
    .assignment-inner-details-main {
      padding: 15px;
      max-width: 100%;
      height: 100%;
    }
  
    .assignment-inner-heading {
      font-size: 1.5rem;
      text-align: center;
    }
  
    .assignment-upload-btn {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
  
    .assignment-heading-upload-btn {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  
    .assignment-inner-details-explanation {
      font-size: 0.95rem;
    }
  
    .assignment-upload-form {
      padding: 20px;
    }
  }
  