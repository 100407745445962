.faq-main-section{
    width: 100%;
    padding: 0 1em;
    overflow: hidden;
}
.faq-main-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}


.discussion-main-content {
    margin-top: 20px;
  }
  
  .search-bar-ask-question-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .discussion-search-bar-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .ask-question-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .ask-question-button:hover {
    background-color: #0056b3;
  }
  
  /* Question cards container */
  .discussion-question-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 20px; /* spacing between the cards */
    margin-top: 20px;
}


  
  /* Modal styling */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
  }
  
  .modal-content h3 {
    margin-bottom: 15px;
  }
  .modal-content input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .modal-content textarea {
    width: 90%;
    height: 100px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .submit-button, .close-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }
  
  .submit-button:hover, .close-button:hover {
    background-color: #0056b3;
  }

  /* Media Query */

  @media (max-width: 1024px) {
    .discussion-question-card-container {
        grid-template-columns: repeat(2, 1fr); /* 2 cards per row for medium screens */
    }
}

@media (max-width: 768px) {
    .discussion-question-card-container {
        grid-template-columns: 1fr; /* 1 card per row for small screens */
    }
}