

/* Card container */
.course-enrolled-card {
  background: linear-gradient(135deg, #f0c389, #EB9F3D);
  border-radius: 12px;
  padding: 15px;
  width: 50%;
  text-align: center;
  color: white;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

/* Heading styles */
.course-enrolled-heading {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
}

/* Course name styling */
.course-name {
  font-size: 18px;
  font-weight: 400;
}

/* Card hover animation */
.course-enrolled-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
}

/* Background Animation Effect */
.course-enrolled-card::before {
  content: "";
  position: absolute;
  top: -100px;
  left: -100px;
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: -1;
  animation: float 6s ease-in-out infinite;
}

.course-enrolled-card::after {
  content: "";
  position: absolute;
  bottom: -100px;
  right: -100px;
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  z-index: -1;
  animation: float 8s ease-in-out infinite;
  animation-delay: 3s;
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-20px) translateX(20px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}
