/* Overall card styling */
.question-card {
    display: flex;
    justify-content: center;
}

.question-card-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 350px;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.question-card-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.question-card-content {
    display: flex;
    flex-direction: column;
}

/* Profile section */
.question-card-profile {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.question-card-profile img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 15px;
}

.profile-name p {
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

/* Question description */
.question-description p {
    font-size: 16px;
    font-weight: 400;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5;
}

/* Button container */
.button-reply-link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Upvote & downvote buttons */
.upvote-downvote-button-container {
    display: flex;
    align-items: center;
}

.upvote-button, .downvote-button {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 8px 12px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upvote-button:hover, .downvote-button:hover {
    background-color: #e0e0e0;
}

.upvote-button p, .downvote-button p {
    margin: 0;
    color: #333;
}

/* Reply button */
.reply-button {
    background-color: #007BFF;
    color: white ;
    border-radius: 8px;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.reply-button:hover {
    background-color: #0056b3;
}

.reply-button p {
    margin: 0;
    color: white;
}
