/* Base Styles */
.navbar-container {
  position: fixed;
  z-index: 9;
  width: 98%;
  top: 0;
  left: 0;
  background-color: white;
  /* font-size: 16px; */
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  box-shadow: 0 6px 15px 0 hsla(0, 0%, 0%, 0.05);
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup {
  /* background: white; */
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 100%;
  
}
.navbar-container.visible {
  transform: translateY(0);
  opacity: 1;
}

.navbar-container.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.navbar-container {
  padding-inline: 1em;
  padding-block: 1em;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  cursor: pointer;
}

.navbar-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-list > ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-list > ul > li {
  list-style: none;
  padding-inline: 1em;
  cursor: pointer;
}

.login-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.login-buttons > button {
  cursor: pointer;
}

.login-buttons > .btn-login {
  width: 150px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  background-color: transparent;
}

.login-buttons > .btn-sign-in {
  width: 150px;
  border-radius: 30px;
  font-weight: bold;
  padding: 1.2em;
  background-color: rgba(6, 39, 132, 0.9);
  color: white;
  font-size: 16px;
  border: none;
  margin-right: 1.5em;
}

.navbar-link {
  padding-block: 8px;
  font-weight: var(--fw-500);
  transition: var(--transition-1);
  color: black;
  display: block;
}

.navbar-item {
  position: relative;
}

.navbar-link:is(:hover, :focus) {
  color: gray;
}

.dropdown > button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0;
  margin-top: 0.12em;
}

.dropdown .navbar-link {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: lightgray;
  top: 120%;
  left: 0;
  min-width: 200px;
  padding: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 9;
}

.dropdown-menu.open {
  display: block;
}

.dropdown-menu .navbar-link {
  padding: 10px 0px;
  color: black;
  text-decoration: none;
}

.dropdown-menu > li {
  list-style: none;
  padding: 1em;
}

.dropdown-menu > ul > hr {
  margin: 0;
}

.dropdown-menu > li:hover {
  background-color: darkgrey;
}

/* Mobile Styles */
.hamburger-menu {
  position: relative;
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  transition: 0.25s ease;
}

.close-menu {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.navbar-list.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  /* height: 100%; */
  width: 90%;
  z-index: 10;
}

.navbar-list.open ul {
  flex-direction: column;
  align-items: center;
  gap: 2em;
  margin-top: 6em;
  padding-left: 3em;
  width: 100%;
}
.navbar-list.open ul > li {
  width: 100%;
  /* text-align: center; */
}
.mobile-sign-in{
  display: none;
}

/* Media Queries */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
    background: none;
    border: none;
    font-size: 2em; /* Increased font size */
    margin-right: 0.5em;
  }

  .close-menu {
    display: block;
    background: none;
    border: none;
    font-size: 2em; /* Increased font size */
    position: absolute;
    top: 10px;
    left: 80%;
  }

  .navbar-list {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: left 0.3s ease-in-out;
    z-index: 10;
  }
  .navbar-container{
    padding-inline: 1em;
  }

  .navbar-list > ul > li {
    padding-inline: 0;
  }

  .navbar-list.open {
    left: 0;
  }

  .navbar-list ul {
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .navbar-list > ul {
    display: none;
  }

  .navbar-list.open > ul {
    display: flex;
  }

  .close-menu.show {
    display: block;
  }
  .btn-login{
    display: none;
  }
  .login-buttons > .btn-sign-in{
    display: none;
  }
  .dropdown-menu{
    position: static;
    top: 0;
    min-width: 100px !important;
  }
  .dropdown-menu.open{
    margin-top: 1em !important;
    position: static;
    width: 80% !important;
    padding-left: 0 !important;

  }
  .dropdown-menu .navbar-link{
    padding: 0;
  }
  .dropdown-menu.open > li{
    padding: 1em;
    margin-top: 0;
  }
  .logo {
    position: relative;
    /* top: 10px; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    z-index: 11;
  }
  .mobile-sign-in {
    display: block;
    padding: 1em 0;
    width: 100%;
    text-align: center;
}
.btn-sign-in {
    background-color: #4E54C8;
    color: white;
    padding: 1em 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
}

}
