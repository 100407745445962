/* FlipCard.css */
.flip-card-container {
  perspective: 1000px;
  width: 250px;
  height: 300px;
  cursor: pointer;
}

.flip-card {
  width: 100%;
  height: 100%;
  position: relative;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s ease-in-out;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.flip-card-front {
  background-color: #fff;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flip-card-overlay {
  position: absolute;
  bottom: 0;
  background: rgba(61, 65, 74, 0.5);
  color: #fff;
  width: 100%;
  padding: 10px;
  text-align: center;
}

.flip-card-back {
  background-color: #fff;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.detailed-description {
  font-size: 16px;
  color: #333;
}
.free-course-play-btn{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.free-course-play-btn > button{
    padding: 10px;
    width: 80%;
    border-radius: 10px;
}
