  
  .sidebar-2 {
    position: sticky;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 56px;
    height: 100vh;
    background: white;
    transition: width 0.4s;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  
  .sidebar-2.open {
    width: 260px;
  }
  
  .sidebar-2 .inner {
    position: relative;
    top: 0;
    left: 0;
    width: 260px;
  }
  
  .sidebar-2 header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 6px;
    background: white;
  }
  
  .sidebar-2-burger {
    width: 44px;
    height: 72px;
    display: grid;
    place-items: center;
    color: #f9f9f9;
  }

  .sidebar-2-burger > .material-symbols-outlined{
    color: #062784;
  }

  .sidebar-btn{
    border: none;
    padding: none;
    text-align: left;
    background-color: transparent;
    cursor: pointer;
  }
  
  .sidebar-2 nav {
    display: grid;
    padding: 6px;
    gap: 2px;
  }
  
  .sidebar-2 nav > .sidebar-btn {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 44px;
    width: 44px;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 1;
    padding: 0 12px;
    border-radius: 8px;
    color: #062784;
    font-weight: bolder;
    text-decoration: none;
  }
  
  .sidebar-2 nav > .sidebar-btn:hover {
    background: rgb(0 0 0 / 30%);
  }
  
  .sidebar-2 header > img,
  .sidebar-2 nav > .sidebar-btn p {
    opacity: 0;
    transition: 0.3s;
  }
  
  .sidebar-2.open :is(nav .sidebar-btn p, header > img) {
    opacity: 1;
  }
  
  .sidebar-2.open nav > .sidebar-btn {
    width: 100%;
  }

  

  /* Mobile Menu Styles */
.mobile-menu {
  display: none;
}

.mobile-menu-burger {
  position: fixed;
  top: 8px;
  left: 10px;
  z-index: 1000;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 28px;
  color: #062784;
}

.mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 40px 20px 20px 20px;
}

.mobile-sidebar nav .sidebar-btn {
  display: flex;
  align-items: center;
  height: 44px;
  font-size: 16px;
  padding: 0 12px;
  border-radius: 8px;
  color: #062784;
  text-decoration: none;
  font-weight: bolder;
}

.mobile-sidebar nav .sidebar-btn:hover {
  background-color: rgb(0 0 0 / 30%);
}

/* Media Query for Mobile */
@media only screen and (max-width: 768px) {
  .sidebar-2 {
    display: none; /* Hide the sidebar on mobile */
  }

  .mobile-menu {
    display: block; /* Show the burger button on mobile */
  }
}
