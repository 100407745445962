.student-main-section {
  width: 95%;
  background-color: #fafafa;
}

.bell-icon {
  margin-top: 1em !important;
  cursor: pointer;
  font-size: 2em;
}

.student-main-container {
  padding: 1em;
}

.heading-bell-container {
  display: flex;
  justify-content: space-between;
}

.profile-notification-container{
  display: flex;
  gap: 1em;
}
.profile-icon {
  margin-top: 1em !important;
  cursor: pointer;
  font-size: 2em;
}
.student-welcome-heading {
  font-size: 1.7em;
  font-weight: 800;
}

.student-upcoming-events {
  border: none;
  border-radius: 30px;
  height: 160px;
  object-fit: cover;
}

.calendar-and-table {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  
}
.calendar-and-table .Basic-section {
  width: 45%;
  margin-bottom: 2em;
}
.calendar-and-table .calendar-section {
  width: 50%;
}
.basic-section-content{
  display: flex;
  gap: 2em;
  height: 220px;
  color: white;
  font-weight: bold;
}

.Slot-calendar-section {
  margin-top: 3em;
}

.upload-view-assignment {
  display: flex;
  gap: 2em;
  margin-top: 2em;
}
.pending-assignment-section {
  
  width: 50%;
  /* height: 250px; */
}
.upload-pending-assignment {
  border-radius: 20px;
  background-color: white;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* MEDIA QUERY */

@media (max-width: 575px) {
  .student-main-section{
    width: 100%;
  }
  .upload-view-assignment{
    flex-wrap: wrap-reverse
  }
  .pending-assignment-section{
    width: 100%;

  }
  .upload-pending-assignment{
    width: 100%;
  }
  .calendar-and-table {
    flex-direction: column;
  }
  .calendar-and-table .Basic-section{
    width: 100%;
  }
  .calendar-and-table .calendar-section{
    width: 100%;
  }
  .student-welcome-heading{
    font-size: 1.3em;
  }
  .profile-icon{
    /* display: none; */
    font-size: 1.5em;
  }
  .bell-icon{
    font-size: 1.5em;
  }
}

/**
   * responsive for large than 768px screen
   */

@media (max-width: 768px) {
  .student-main-section{
    width: 100%;
  }
  .calendar-section{
    display: none;
  }
  .calendar-and-table {
    flex-direction: column;
  }
  .calendar-and-table .Basic-section {
    width: 100%;
  }
  .calendar-and-table .calendar-section {
    width: 100%;
  }
  .basic-section-content{
    gap: 1em;
  }
}

@media (max-width: 992px) {
}

/**
   * responsive for large than 1200px screen
   */

@media (max-width: 1200px) {
}
