.free-course-section {
  height: 100%;
  margin-top: 2em;
  transition: background-color 1s ease-in-out;
}
.free-course-container {
  height: inherit;
  padding: 2em;
}
.free-course-section-heading > h2 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 1.5em;
}
.free-course-tabs {
  display: flex;
  justify-content: space-evenly;
}
.free-course-tabs > div {
  cursor: pointer;
  font-size: 1.2em;
  font-weight: 600;
  background-color: transparent;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: #a1a1a1;
  position: relative;
}
.course-tab::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #a1a1a1;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-in;
  }
  
  .course-tab:hover::after,
  .course-tab.active::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    
}
.course-tab.active::after{
    background-color: #000;
}

/* .course-tab::after {
  opacity: 0;
} */
.course-tab.active {
  color: #000;
}
.free-course-cards {
  display: flex;
  justify-content: space-evenly;
  align-content: space-evenly;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 2.5em;
}

@media (max-width: 768px){

    .free-course-section-heading > h2{
        font-size: 1.5rem;
    }

}