

/* Card container styling */
.assigned-trainer-card {
  background: linear-gradient(135deg, #0961A2, #2F327D);
  padding: 15px;
  width: 50%;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;
}

/* Header styles */
.assigned-trainer-header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0;
}

/* Trainer name styles */
.trainer-name {
  font-size: 18px;
  font-weight: 400;
}

/* Hover effect with shadow and transform */
.assigned-trainer-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

/* Glowing animation effect */
.assigned-trainer-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  z-index: -1;
  filter: blur(15px);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.assigned-trainer-card:hover::before {
  opacity: 1;
}

/* Gradient animation effect */
.assigned-trainer-card::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.15), transparent 70%);
  animation: animate-glow 5s infinite linear;
  transform-origin: center;
  z-index: -2;
}

@keyframes animate-glow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
