.assignmentmain-section{
    width: 100%;
    padding: 0 1em;
    overflow: hidden;
}
.assignmentmain-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.assignment-main-cards-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1em;
}