.promo-section{
    background-color: #F9F5F2;
}
.promo-container{
    padding: 3em;
}
.promo-heading-desc-container{
    display: flex;
    width: 100%;
    margin-top: 2em;
}
.promo-heading-desc-container > h2{
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0;
    width: 40%;
}
.promo-heading-desc-container > p{
    font-size: 1.3rem;
    margin-left: 5em;
    width: 40%;
}
.video-promo-slogan-container{
    display: flex;
    width: 100%;
}
.promo-slogan-container{
    width: 30%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 8em;
}
.promo-video-container{
    width: 70%;
}
.course-number-container{
    height: 90px;
    width: 55%;
    padding: 1em 2.6em;
    background-color: #97C680;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
    z-index: 2;
    left: 7em;
    
}

.para-bold{
    font-size: 3rem;
}
.para-bar{
    font-size: 3rem;
    font-weight: 200;
    padding-inline: 0.5em;
}
.course-number-description{
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
}

.promo-video-box{
    background-color: white;
    width: 95%;
    height: 75%;
    border-radius: 20px;
    padding-block: 1em;
    position: relative;
    z-index: 1;
    top: -3.5em;
    object-fit: cover;
}
.promo-video-box > video{
    width: 100%;
    height: 95%;
    object-fit: cover;
}
.slogan-logo{
    display: flex;
    flex-direction: column;
    gap: 2.5em;
}
.slogan-logo-container
{
    height: 60px;
    width: 60px;
    background-color: white;
    border-radius: 50%;
}
.dashed-connector{
    border: 1px dashed black;
    width: 55px;
    transform: rotate(90deg);
}
.slogan-para{
    padding-right: 3em;
    
}
.slogan-big{
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
}
.slogan-small{
    margin-top: 0.5em;
}
.big{
    margin-top: 3em;
}

@media (max-width: 768px){
    .promo-heading-desc-container{
        flex-wrap: wrap;
    }
    .promo-heading-desc-container > h2{
        width: 100%;
        font-size: 1.5rem;
    }
    .promo-heading-desc-container > p{
        width: 100%;
        font-size: 1.1rem;
        margin-left: 0;
    }
    .promo-video-container{
        width: 100%;
    }
    .video-promo-slogan-container{
        flex-direction: column-reverse;
    }
    .course-number-container{
        height: 20px;
        width: 20%;
        top: -1.5em;
        margin-top: 1em;
    }
    .para-visible{
        display: none;
    }
    .para-bold{
        font-size: 1.5rem;
        font-weight: 600;
    }
    .course-number-description{
        font-size: 0.5rem;
    }
    .promo-slogan-container{
        margin-top: 0;
    }


}