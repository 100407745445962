/* QuestionDetail.css */

.question-detail-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .question-detail-container > h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .question-detail-container > p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .replies-section {
    margin-top: 2rem;
  }
  
  .replies-section h3 {
    font-size: 1.5rem;
    color: #333;
    border-bottom: 2px solid #ddd;
    padding-bottom: 0.5rem;
  }
  
  .reply {
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #fff;
    border-left: 4px solid #007BFF;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .reply-name {
    margin: 0;
    color: #333;
  }
  
  .reply strong {
    color: #007BFF;
  }
  
  .reply-form {
    margin-top: 2rem;
  }
  
  .reply-form textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 1rem;
    resize: none;
    background-color: #f1f1f1;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .reply-form button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reply-form button:hover {
    background-color: #0056b3;
  }
  