.calendar-container {
  width: 90%;
  height: 400px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 2em;
}

.calendar-container:hover {
  transform: scale(1.02);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.rbc-calendar {
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.rbc-calendar .rbc-header {
  background-color: #062784;
  color: white;
  font-weight: bold;
  padding: 18px;
  text-align: center;
}

.rbc-calendar .rbc-day-bg {
  transition: background-color 0.3s ease;
}

.rbc-calendar .rbc-day-bg:hover {
  background-color: #f0f0f0;
}

.rbc-calendar .rbc-event {
  background-color: #EA9D3D;
  border-radius: 4px;
  font-size: 10px;
  color: white;
  padding: 2px 6px;
  transition: background-color 0.3s ease;
}

.rbc-calendar .rbc-event:hover {
  background-color: #388e3c;
}

.rbc-calendar .rbc-today {
  background-color: #f0f0f0;
}

.rbc-calendar .rbc-event-label {
  color: white;
}
/* Style for the navigation buttons (Next, Previous, Today) */
.rbc-toolbar button {
  background-color: #062784;
  color: white;
  font-size: 10px;
  padding: 5px 10px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.rbc-toolbar button:hover {
  background-color: #002d72;
  transform: scale(1.05);
}

.rbc-toolbar {
  font-size: 16px; 
  margin-bottom: 10px; 
}

.rbc-toolbar .rbc-btn-group {
  gap: 5px; 
}

/* Make toolbar responsive */
@media (max-width: 768px) {
  .rbc-toolbar button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .rbc-toolbar .rbc-btn-group {
    gap: 10px;
  }
}


@media (max-width: 768px) {
  .calendar-container {
    width: 95%;
    height: 500px;
  }
}
